/*----------------------- ログイン画面 --------------------------*/
.wqc_login_outer{
  background:radial-gradient(rgb(229, 239, 250),80%, rgb(74, 15, 168));
  background:-ms-radial-gradient(rgb(229, 239, 250), rgb(74, 15, 168));
}
.wqc_login_title{
  margin-bottom:40px;
  font-size: 4rem;
  font-weight: bolder;
  border:15px solid #c33;  
  border-top: 1px solid #d04;
  border-radius:50%; 
  /* border-top:5px dashed #556DAE;   */
  color: rgba(106, 63, 161, 0.795);
  padding:0px 30px;
  text-shadow:
    1px  1px 0 #FFF, -2px -2px 0 rgb(78, 39, 184),
    -1px 1px 0 #FFF, 1px  -1px 0 rgb(160, 86, 179),
    0px  1px 0 #FFF, 0    -1px 0 rgb(160, 86, 179),
    -1px 0   0 #FFF, 1px  0    0 rgb(160, 86, 179);
  font-family: Georgia, 'Times New Roman', Times, serif;
  box-shadow: 1px 3px 3px 0px  #908b8b;
}

.wqc_login_inner{
  border: 3px solid rgb(255, 255, 255);
  background-color: #ffffff;
  padding: 50px;
  border-radius: 10px;
  width: 450px;
  box-shadow: 1px 3px 3px 0px  #cccccc;
}

.wqc_login_text{
  border:1px solid #595;
  border-radius: 10px;
  margin-bottom:30px;
  line-height: 2rem;
  padding: 0 5px;  
}

.login_btn{
  border-radius: 10px;
  border: none;
  padding: 8px;
  cursor: pointer;
  background-color: #747;
  color: #fff;
  border:3px solid #747;
  font-weight:bold; 
  font-size: 18px;
  margin: 10px 0px 25px 0px;
  transition: 0.3s;
  user-select:none;
  -webkit-user-select: none;
  -moz-user-select: none;
}
  
.login_btn:hover{
  background-color: #ffffff;
  color:  #747;
}

.wqc_login_check{
  align-self: flex-end; 
  font-size: 0.9rem;
  margin: 0px 8px 40px 8px;
}

.wqc_login_sentens{ 
  font-size: 0.9rem;
  margin-bottom:50px;
}
.wqc_login_forget{
  margin-right:16px;
}
.wqc_login_new{
  margin-left:16px;
}

/*---------------------------------------------------------*/

.header-icon{
  margin: 8px;
}

.header-icon:hover{
  opacity: 0.3;
}

.header-icon_logout{
  margin:0px 24px 0x 8px;
}

.close_button_right {
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: block;
  position: absolute;
  right:22px;
  top:120px;
  font-family: Helvetica, STHeiti;
  _font-family: '\u9ed1\u4f53', 'Book Antiqua', Palatino;
  font-size: 22px;
  border-radius: 30px;
  background: #999;
  color: #FFF;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .3);
  -moz-transition: linear .2s;
  -webkit-transition: linear .2s;
  transition: linear .2s;
  padding: 0;
  text-align: center;
  text-decoration: none;
  outline: none;
  cursor: pointer;
}
.close_button_right:hover {
  width: 34px;
  height: 34px;
  line-height: 34px;
  right:20px;
  top:118px;
  color: #FFF;
  box-shadow: 0 1px 3px rgba(209, 40, 42, .5);
  background: #d1282a;
  border-radius: 34px;
  transition: all 0.3s ease-out;
  opacity: 0.8;
}