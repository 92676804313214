.loadable-button {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  height: 40px;
  /* font-size: 12px; */
  /* font-weight: bold; */
  /* border: 1px; */
  border-radius: 2px;
  background-color: #4030d9;
  color: #ffffff;
  min-width: 80px;
  /*padding: 8px 8px;*/
  padding-right: 16px;
  padding-left: 10px;
  margin-left: 8px;
  user-select: none;
  cursor: pointer;
}

.loadable-button:hover {
  opacity: 0.7;
}

.loadable-icon-loading {
  margin-right: 8px;
}

.loadable-button-loading {
  position: relative;
  cursor: not-allowed;
  user-select: none;
}

.loadable-icon-loading {
  font-size: 28px;
  transition: transform .3s ease-in-out;
  transition: transform .3s ease-in-out;
  will-change: transform;
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  cursor: not-allowed;
  user-select: none;
}

.loadable-icon-loading-spin {
  display: inline-block;
  animation: loadingCircle 1s infinite linear;
}

@-webkit-keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}