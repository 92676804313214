/*
* For GuideLine Component 
*/

.x_guide_line {
  height: 99%;
  position: absolute;
  z-index: 2499;
  border-right: 2px dashed #676767;
  left: var(--guide-line-left);
  margin: 0px 0px !important;
  top: 0;
}

.y_guide_line {
  width: 99%;
  position: absolute;
  z-index: 2499;
  height: 2px;
  /* background-color: red; */
  border-bottom: 2px dashed #676767;
  top: var(--guide-line-top);
  margin: 0px 0px !important;
}



/*
* For SimpleTable Component 
*/
.smp-table-tr:nth-child(odd) {
  background: var(--oddColor);
}

.smp-table-tr:nth-child(even) {
  background: var(--evenColor);
}

.smp-table-tr:hover {
  background: var(--hoverColor);
}

.smp-table-tr {
  transition: 0.2s ease-in-out;
}


/*
* For SelectCollection Component 
*/

div.sc-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: rgb(245, 245, 245);
  border-radius: 5px;
}

div.sc-selector-area,
div.sc-result-area {
  width: 100%;
}

/* div.sc-selector-area {
  margin-top: 30px;
} */

div.sc-result-area {
  background-color: #fff;
}

div.option-pool-wrapper {
  /* background-color: rgb(245, 245, 245);
  border-radius: 5px; */
  max-height: calc(100% - 61px);
  overflow-y: auto;
}

div.option-pool {
  margin-top: 5px;
  display: flex;
  display: -webkit-flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

div.filter-bar {
  width: 100%;
  display: inline-flex;
  align-items: flex-end;
  justify-content: flex-start;
  overflow: auto;
  background-color: rgb(91, 186, 106);
  padding: 5px;
  border-radius: 5px;
  flex-wrap: wrap;
  min-height: 61px;
  /* for scroll-y */
}

div.sc-filter-box {
  position: relative;
  min-height: 36px;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 5px;
  padding-left: 3px;
  /* margin-top: 30px; */
}

div.sc-filter-box p {
  transition: all 0.4s;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-weight: bold;
}


div.sc-arrow {
  right: 0;
  top: calc(50% -15px);
  cursor: pointer;
  width: 30px;
  height: 30px;
  right: -30px;
  border-radius: 50%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

div.sc-arrow:before,
div.sc-arrow:after {
  content: '';
  position: absolute;
  display: block;
  width: 2px;
  height: 7px;
  top: 11px;
  border-bottom: 8px solid #fff;
  transition: 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

div.sc-arrow:before {
  -webkit-transform: rotate(-130deg);
  transform: rotate(-130deg);
}

div.sc-arrow:after {
  -webkit-transform: rotate(130deg);
  transform: rotate(130deg);
}

div.sc-arrow:hover {
  transition: 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  background: rgba(125, 212, 1380, 0.8);
}

ul.sc-ul-box {
  position: absolute;
  list-style-type: none;
  border-radius: 5px;
  width: 100%;
  min-height: 40px;
  overflow: hidden;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.6);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}

ul.sc-ul-box li {
  min-width: 200px;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding: 5px 10px 5px 10px;
  font-size: 12PX;
}


ul.sc-ul-box li:hover {
  background-color: darkseagreen;
}

div.sc-option,
div.sc-selected-option,
div.sc-selected-filter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 2px 4px;
  margin-right: 5px;
  background-color: #fff;
  border-radius: 5px;
  cursor: pointer;
  min-height: 28px;
  max-height: 30px;
  min-width: 30px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-size: 12px;
}

div.sc-selected-filter {
  height: calc(100% - 3px);
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

div.sc-option {
  max-width: 90%;
  background-color: #c7ecee;
  margin: 3px;
  overflow: hidden;
}

div.option-pool .selected {
  background-color: cornflowerblue;
}


/*
* For Switcher Component 
*/

div.smart-switch-wrapper {
  height: 20px;
  width: 46px;
  border-radius: 10px;
  background-color: #d1d1d1;
  overflow: hidden;
}

div.smart-switch-bar {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  /* background-color: #5bba6a; */
  display: flex;
  justify-items: flex-end;
  flex-direction: row-reverse;
  /* transition: 0.2s ease-in-out; */
}

div.smart-switch-button {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  border: 4px solid #ccc;
}


div.smart-switch-disabled{
  cursor: none;
  pointer-events: none;
  background: #8f8f8f;
}

div.smart-switch-button-disabled{
  border: 4px solid #8f8f8f;
}

div.smart-switch-button-shadow {
  /* box-shadow: -1px 0px 6px #7c887e; */
  border: 4px solid #5bba6a;
}

.smart-switch-on-init {
  -webkit-transform: translateX(0px);
          transform: translateX(0px);
}

.smart-switch-off-init {
  -webkit-transform: translateX(-26px);
          transform: translateX(-26px);
}


.smart-switch-on {
  -webkit-animation: switch-on 0.15s ease-in-out;
          animation: switch-on 0.15s ease-in-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.smart-switch-off {
  -webkit-animation: switch-off 0.15s ease-in-out;
          animation: switch-off 0.15s ease-in-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}


@-webkit-keyframes switch-on {
  0% {
    -webkit-transform: translateX(-26px);
            transform: translateX(-26px);
    background-color: #d1d1d1;
  }

  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
    background-color: #5bba6a;
  }
}


@keyframes switch-on {
  0% {
    -webkit-transform: translateX(-26px);
            transform: translateX(-26px);
    background-color: #d1d1d1;
  }

  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
    background-color: #5bba6a;
  }
}


@-webkit-keyframes switch-off {
  0% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);   
    background-color: #5bba6a;
  }

  100% {
    -webkit-transform: translateX(-26px);
            transform: translateX(-26px);   
    background-color: #d1d1d1;
  }
}


@keyframes switch-off {
  0% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);   
    background-color: #5bba6a;
  }

  100% {
    -webkit-transform: translateX(-26px);
            transform: translateX(-26px);   
    background-color: #d1d1d1;
  }
}


/* hideable footer animation */
.smart-footer-handler{
  width: 72px;
  height: var(--footerMinHeight);
  margin: 4px;
  border-radius: 8px;
  background-color: #eee;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  text-align: center;
}

.smart-footer-handler:hover{
  background-color: #ccc;
}



.smart-footer-expand {
  -webkit-animation: switch-expand 0.25s ease-out;
          animation: switch-expand 0.25s ease-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
@-webkit-keyframes switch-expand {
  0% {
    height: var(--footerMinHeight);
  }

  100% {
    height: var(--footerHeight);
  }
}
@keyframes switch-expand {
  0% {
    height: var(--footerMinHeight);
  }

  100% {
    height: var(--footerHeight);
  }
}

.smart-footer-close {
  -webkit-animation: switch-close 0.25s ease-out;
          animation: switch-close 0.25s ease-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@-webkit-keyframes switch-close {
  100% {
    height: var(--footerMinHeight);
  }

  0% {
    height: var(--footerHeight);
  }
}

@keyframes switch-close {
  100% {
    height: var(--footerMinHeight);
  }

  0% {
    height: var(--footerHeight);
  }
}


/* snap animation */
.smart-snapping {
  -webkit-animation: snapping 0.35s ease-in-out;
          animation: snapping 0.35s ease-in-out;
}

@-webkit-keyframes snapping {
  0% {
    border: 2px solid #676767;
  }

  50% {
    border: 12px solid #676767;
  }

  100% {
    border: 2px solid #676767;
  }
}

@keyframes snapping {
  0% {
    border: 2px solid #676767;
  }

  50% {
    border: 12px solid #676767;
  }

  100% {
    border: 2px solid #676767;
  }
}
.loadable-button {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  height: 40px;
  /* font-size: 12px; */
  /* font-weight: bold; */
  /* border: 1px; */
  border-radius: 2px;
  background-color: #4030d9;
  color: #ffffff;
  min-width: 80px;
  /*padding: 8px 8px;*/
  padding-right: 16px;
  padding-left: 10px;
  margin-left: 8px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
}

.loadable-button:hover {
  opacity: 0.7;
}

.loadable-icon-loading {
  margin-right: 8px;
}

.loadable-button-loading {
  position: relative;
  cursor: not-allowed;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.loadable-icon-loading {
  font-size: 28px;
  transition: -webkit-transform .3s ease-in-out;
  transition: transform .3s ease-in-out;
  transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
  transition: transform .3s ease-in-out;
  will-change: transform;
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  cursor: not-allowed;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.loadable-icon-loading-spin {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
          animation: loadingCircle 1s infinite linear;
}

@-webkit-keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}
/*----------------------- ログイン画面 --------------------------*/
.wqc_login_outer{
  background:radial-gradient(rgb(229, 239, 250),80%, rgb(74, 15, 168));
  background:-ms-radial-gradient(rgb(229, 239, 250), rgb(74, 15, 168));
}
.wqc_login_title{
  margin-bottom:40px;
  font-size: 4rem;
  font-weight: bolder;
  border:15px solid #c33;  
  border-top: 1px solid #d04;
  border-radius:50%; 
  /* border-top:5px dashed #556DAE;   */
  color: rgba(106, 63, 161, 0.795);
  padding:0px 30px;
  text-shadow:
    1px  1px 0 #FFF, -2px -2px 0 rgb(78, 39, 184),
    -1px 1px 0 #FFF, 1px  -1px 0 rgb(160, 86, 179),
    0px  1px 0 #FFF, 0    -1px 0 rgb(160, 86, 179),
    -1px 0   0 #FFF, 1px  0    0 rgb(160, 86, 179);
  font-family: Georgia, 'Times New Roman', Times, serif;
  box-shadow: 1px 3px 3px 0px  #908b8b;
}

.wqc_login_inner{
  border: 3px solid rgb(255, 255, 255);
  background-color: #ffffff;
  padding: 50px;
  border-radius: 10px;
  width: 450px;
  box-shadow: 1px 3px 3px 0px  #cccccc;
}

.wqc_login_text{
  border:1px solid #595;
  border-radius: 10px;
  margin-bottom:30px;
  line-height: 2rem;
  padding: 0 5px;  
}

.login_btn{
  border-radius: 10px;
  border: none;
  padding: 8px;
  cursor: pointer;
  background-color: #747;
  color: #fff;
  border:3px solid #747;
  font-weight:bold; 
  font-size: 18px;
  margin: 10px 0px 25px 0px;
  transition: 0.3s;
  -ms-user-select:none;
      user-select:none;
  -webkit-user-select: none;
  -moz-user-select: none;
}
  
.login_btn:hover{
  background-color: #ffffff;
  color:  #747;
}

.wqc_login_check{
  align-self: flex-end; 
  font-size: 0.9rem;
  margin: 0px 8px 40px 8px;
}

.wqc_login_sentens{ 
  font-size: 0.9rem;
  margin-bottom:50px;
}
.wqc_login_forget{
  margin-right:16px;
}
.wqc_login_new{
  margin-left:16px;
}

/*---------------------------------------------------------*/

.header-icon{
  margin: 8px;
}

.header-icon:hover{
  opacity: 0.3;
}

.header-icon_logout{
  margin:0px 24px 0x 8px;
}

.close_button_right {
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: block;
  position: absolute;
  right:22px;
  top:120px;
  font-family: Helvetica, STHeiti;
  _font-family: '\u9ed1\u4f53', 'Book Antiqua', Palatino;
  font-size: 22px;
  border-radius: 30px;
  background: #999;
  color: #FFF;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .3);
  transition: linear .2s;
  padding: 0;
  text-align: center;
  text-decoration: none;
  outline: none;
  cursor: pointer;
}
.close_button_right:hover {
  width: 34px;
  height: 34px;
  line-height: 34px;
  right:20px;
  top:118px;
  color: #FFF;
  box-shadow: 0 1px 3px rgba(209, 40, 42, .5);
  background: #d1282a;
  border-radius: 34px;
  transition: all 0.3s ease-out;
  opacity: 0.8;
}
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  box-sizing: border-box;
  font-family:'メイリオ', 'Meiryo','ＭＳ ゴシック','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3',sans-serif;
}
html{
  background: #E6E6E6;
}

body {
  line-height: 1;
  min-height: 100vh;
  /* background: #E6E6E6; */

}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input,
select {
  vertical-align: middle;
  box-sizing: border-box;
  font-size: 16px;
  color: #000;
}

ol,
ul {
  list-style: none;
}

button {
  box-sizing: border-box;
  font-size: 16px;
  padding: 0px;
  color:#000;
  font-family:'メイリオ', 'Meiryo','ＭＳ ゴシック','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3',sans-serif;
}

textarea {
  font-family: auto;
}

/*--------------- ここまではリセットcssです ----------------------*/
