/*
* For GuideLine Component 
*/

.x_guide_line {
  height: 99%;
  position: absolute;
  z-index: 2499;
  border-right: 2px dashed #676767;
  left: var(--guide-line-left);
  margin: 0px 0px !important;
  top: 0;
}

.y_guide_line {
  width: 99%;
  position: absolute;
  z-index: 2499;
  height: 2px;
  /* background-color: red; */
  border-bottom: 2px dashed #676767;
  top: var(--guide-line-top);
  margin: 0px 0px !important;
}



/*
* For SimpleTable Component 
*/
.smp-table-tr:nth-child(odd) {
  background: var(--oddColor);
}

.smp-table-tr:nth-child(even) {
  background: var(--evenColor);
}

.smp-table-tr:hover {
  background: var(--hoverColor);
}

.smp-table-tr {
  transition: 0.2s ease-in-out;
}


/*
* For SelectCollection Component 
*/

div.sc-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: rgb(245, 245, 245);
  border-radius: 5px;
}

div.sc-selector-area,
div.sc-result-area {
  width: 100%;
}

/* div.sc-selector-area {
  margin-top: 30px;
} */

div.sc-result-area {
  background-color: #fff;
}

div.option-pool-wrapper {
  /* background-color: rgb(245, 245, 245);
  border-radius: 5px; */
  max-height: calc(100% - 61px);
  overflow-y: auto;
}

div.option-pool {
  margin-top: 5px;
  display: flex;
  display: -webkit-flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

div.filter-bar {
  width: 100%;
  display: inline-flex;
  align-items: flex-end;
  justify-content: flex-start;
  overflow: auto;
  background-color: rgb(91, 186, 106);
  padding: 5px;
  border-radius: 5px;
  flex-wrap: wrap;
  min-height: 61px;
  /* for scroll-y */
}

div.sc-filter-box {
  position: relative;
  min-height: 36px;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 5px;
  padding-left: 3px;
  /* margin-top: 30px; */
}

div.sc-filter-box p {
  transition: all 0.4s;
  user-select: none;
  font-weight: bold;
}


div.sc-arrow {
  right: 0;
  top: calc(50% -15px);
  cursor: pointer;
  width: 30px;
  height: 30px;
  right: -30px;
  border-radius: 50%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

div.sc-arrow:before,
div.sc-arrow:after {
  content: '';
  position: absolute;
  display: block;
  width: 2px;
  height: 7px;
  top: 11px;
  border-bottom: 8px solid #fff;
  transition: 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

div.sc-arrow:before {
  -webkit-transform: rotate(-130deg);
  transform: rotate(-130deg);
}

div.sc-arrow:after {
  -webkit-transform: rotate(130deg);
  transform: rotate(130deg);
}

div.sc-arrow:hover {
  transition: 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  background: rgba(125, 212, 1380, 0.8);
}

ul.sc-ul-box {
  position: absolute;
  list-style-type: none;
  border-radius: 5px;
  width: 100%;
  min-height: 40px;
  overflow: hidden;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(10px);
}

ul.sc-ul-box li {
  min-width: 200px;
  cursor: pointer;
  user-select: none;
  padding: 5px 10px 5px 10px;
  font-size: 12PX;
}


ul.sc-ul-box li:hover {
  background-color: darkseagreen;
}

div.sc-option,
div.sc-selected-option,
div.sc-selected-filter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 2px 4px;
  margin-right: 5px;
  background-color: #fff;
  border-radius: 5px;
  cursor: pointer;
  min-height: 28px;
  max-height: 30px;
  min-width: 30px;
  user-select: none;
  font-size: 12px;
}

div.sc-selected-filter {
  height: calc(100% - 3px);
  user-select: none;
}

div.sc-option {
  max-width: 90%;
  background-color: #c7ecee;
  margin: 3px;
  overflow: hidden;
}

div.option-pool .selected {
  background-color: cornflowerblue;
}


/*
* For Switcher Component 
*/

div.smart-switch-wrapper {
  height: 20px;
  width: 46px;
  border-radius: 10px;
  background-color: #d1d1d1;
  overflow: hidden;
}

div.smart-switch-bar {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  /* background-color: #5bba6a; */
  display: flex;
  justify-items: flex-end;
  flex-direction: row-reverse;
  /* transition: 0.2s ease-in-out; */
}

div.smart-switch-button {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  border: 4px solid #ccc;
}


div.smart-switch-disabled{
  cursor: none;
  pointer-events: none;
  background: #8f8f8f;
}

div.smart-switch-button-disabled{
  border: 4px solid #8f8f8f;
}

div.smart-switch-button-shadow {
  /* box-shadow: -1px 0px 6px #7c887e; */
  border: 4px solid #5bba6a;
}

.smart-switch-on-init {
  transform: translateX(0px);
}

.smart-switch-off-init {
  transform: translateX(-26px);
}


.smart-switch-on {
  animation: switch-on 0.15s ease-in-out;
  animation-fill-mode: forwards;
}

.smart-switch-off {
  animation: switch-off 0.15s ease-in-out;
  animation-fill-mode: forwards;
}


@keyframes switch-on {
  0% {
    transform: translateX(-26px);
    background-color: #d1d1d1;
  }

  100% {
    transform: translateX(0px);
    background-color: #5bba6a;
  }
}


@keyframes switch-off {
  0% {
    transform: translateX(0px);   
    background-color: #5bba6a;
  }

  100% {
    transform: translateX(-26px);   
    background-color: #d1d1d1;
  }
}


/* hideable footer animation */
.smart-footer-handler{
  width: 72px;
  height: var(--footerMinHeight);
  margin: 4px;
  border-radius: 8px;
  background-color: #eee;
  cursor: pointer;
  user-select: none;
  text-align: center;
}

.smart-footer-handler:hover{
  background-color: #ccc;
}



.smart-footer-expand {
  animation: switch-expand 0.25s ease-out;
  animation-fill-mode: forwards;
}
@keyframes switch-expand {
  0% {
    height: var(--footerMinHeight);
  }

  100% {
    height: var(--footerHeight);
  }
}

.smart-footer-close {
  animation: switch-close 0.25s ease-out;
  animation-fill-mode: forwards;
}

@keyframes switch-close {
  100% {
    height: var(--footerMinHeight);
  }

  0% {
    height: var(--footerHeight);
  }
}


/* snap animation */
.smart-snapping {
  animation: snapping 0.35s ease-in-out;
}

@keyframes snapping {
  0% {
    border: 2px solid #676767;
  }

  50% {
    border: 12px solid #676767;
  }

  100% {
    border: 2px solid #676767;
  }
}